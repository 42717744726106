import api from './api';

class MemberService {
  getDataMembers(dataForm) {
    return api.post('/merchant/members', dataForm);
  }

  createNewMember() {
    return api.post('/merchant/member');
  }

  getDataMemberDetail(memberId) {
    return api.post('/merchant/member/' + memberId);
  }

  addOrUpdateMember(dataForm) {
    return api.post('/merchant/member-create-or-update', dataForm);
  }

  deleteMember(memberId) {
    return api.post('/merchant/member/delete/' + memberId);
  }

  changeStatus(memberId) {
    return api.post('/merchant/member/change-status/' + memberId);
  }

  myRewards(memberId, dataForm) {
    return api.post('/merchant/member/my-reward/' + memberId, dataForm);
  }

  getVoucherAvailabel() {
    return api.post('/merchant/voucher-available');
  }

  bulkVoucher(type, dataForm) {
    return api.post('/merchant/member/bulk/voucher/' + type, dataForm);
  }

  updateRemark(memberId, dataForm) {
    return api.post('/merchant/member/update-remark/' + memberId, dataForm);
  }

  updateProfileAndRemark(dataForm) {
    return api.post('/merchant/member/update-profile-remark', dataForm);
  }

  myTransaction(memberId, dataForm) {
    return api.post('/merchant/member/my-transaction/' + memberId, dataForm);
  }

  getFieldsMemberProfile() {
    return api.get('/merchant/get-field-member-profile');
  }

  updateFieldsMemberProfile(dataForm) {
    return api.post('/merchant/update-field-diplay-or-required', dataForm);
  }

  addNewFieldsMemberProfile(dataForm) {
    return api.post('/merchant/add-new-field-member-profile', dataForm);
  }

  deleteFieldsMemberProfile(id) {
    return api.post('/merchant/delete-field-member-profile/'+id);
  }

  getCountriesForDropdown() {
    return api.get('/countries-for-dropdown');
  }
}

export default new MemberService();
