<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddOption"
      id="show-modal-add-option"
    ></material-button>
    <material-button
      class="d-none"
      data-bs-dismiss="modal"
      data-bs-target="#modalAddOption"
      id="hide-modal-add-option"
    ></material-button>
    <div
      class="modal fade"
      id="modalAddOption"
      tabindex="-1"
      aria-hidden="true"
      style="z-index: 9999"
    >
      <div class="modal-dialog modal-dialog-centered modal-option">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ isEditOption ? "Edit Option" : "Add Option" }}
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div class="w-100">
              <ul id="general-tab" class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    id="nav_item_1"
                    class="nav-link"
                    :class="{ active: type == 1 }"
                    @click="changeTab(1)"
                    >General</a
                  >
                </li>
                <li class="nav-item" v-if="this.isEditOption">
                  <a id="nav_item_2" class="nav-link" @click="changeTab(2)"
                    >Attached Items</a
                  >
                </li>
              </ul>
            </div>
            <div v-if="type == 1">
              <div class="row align-items-center w-100 m-0 mt-5">
                <div class="col-4 ps-0">
                  Name<span class="text-danger">*</span>
                </div>
                <div class="col-8">
                  <material-input
                    v-model="option.name"
                    type="text"
                    id="option_group_name"
                    name="option_group_name"
                    placeholder="Enter name"
                  />
                </div>
              </div>
              <span
                class="is-invalid"
                v-if="fieldRequired.isOptionGroupNameRequired"
                >The field is required.</span
              >
              <div class="row align-items-center w-100 m-0 mt-3">
                <div class="col-4 ps-0">
                  Price<span class="text-danger">*</span>
                </div>
                <div class="col-8">
                  <Multiselect
                    v-model="option.price_list_id"
                    :options="priceList"
                    placeholder="Select price"
                    :canClear="false"
                  />
                </div>
              </div>
              <span
                class="is-invalid"
                v-if="fieldRequired.isOptionPriceRequired"
                >The field is required.</span
              >
              <div class="row align-items-center w-100 m-0 mt-3">
                <div class="col-6 ps-0">
                  <div class="d-flex align-items-center">
                    <div class="me-3">Min</div>
                    <material-input
                      v-model="option.min_qty"
                      type="number"
                      id="min_qty"
                      name="min_qty"
                      placeholder="Enter"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex align-items-center">
                    <div class="me-3">
                      Max<span class="text-danger">*</span>
                    </div>
                    <material-input
                      v-model="option.max_qty"
                      type="number"
                      id="max_qty"
                      name="max_qty"
                      placeholder="Enter"
                    />
                  </div>
                </div>
              </div>
              <span
                class="is-invalid"
                v-if="fieldRequired.isOptionMaxQtyRequired"
                >The field is required.</span
              >
              <div class="row align-items-center w-100 m-0 mt-3">
                <div class="col-4 ps-0">
                  Item Max<span class="text-danger">*</span>
                </div>
                <div class="col-8">
                  <material-input
                    v-model="option.item_max_qty"
                    type="number"
                    id="item_max_qty"
                    name="item_max_qty"
                    placeholder="Enter"
                  />
                </div>
              </div>
              <span
                class="is-invalid"
                v-if="fieldRequired.isOptionItemMaxQtyRequired"
                >The field is required.</span
              >
              <div class="row align-items-center w-100 m-0 mt-3">
                <div class="col-4 ps-0">
                  Dining Option<span class="text-danger">*</span>
                </div>
                <div class="col-8">
                  <Multiselect
                    v-model="option.dining_option"
                    :options="diningOptionDataList"
                    :canClear="false"
                  />
                </div>
              </div>
              <div class="row align-items-center w-100 m-0 mt-3">
                <div class="col-4 ps-0">Remark</div>
                <div class="col-8">
                  <material-checkbox
                    :checked="option.is_remark"
                    v-model="option.is_remark"
                    name="remark"
                    class="align-items-center"
                  ></material-checkbox>
                </div>
              </div>
              <div class="row align-items-center w-100 m-0 mt-3">
                <div class="col-4 ps-0">Required?</div>
                <div class="col-8">
                  <material-checkbox
                    :checked="option.is_required"
                    v-model="option.is_required"
                    name="required"
                    class="align-items-center"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="w-100 text-end mt-5">
                <material-button
                  class="mx-2 btn btn-sm btn-background"
                  @click="addItem()"
                >
                  Add Items
                </material-button>
              </div>
              <div class="list-item" v-if="attachedToItem.length">
                <div
                  v-for="(item, index) in attachedToItem"
                  :key="index"
                  class="item-css"
                >
                  <i class="material-icons text-danger" @click="removeItem(index, item.item_id)">clear</i>
                  <img class="item-img" :src="item.item_image" alt="" />
                  <div style="position: relative; width: 100%">
                    <label>{{ item?.item_no }}</label
                    ><br />
                    <label>{{ item?.item_name }}</label>
                  </div>
                </div>
              </div>
              <div v-else class="text-center w-100 mt-3">No item available</div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex mt-3">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="saveOption()"
              >
                Save
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
            </div>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import SettingGeneralService from "../services/settingGeneral.service";
import ModifierOptionGroupService from "../services/product/modifierOptionGroup";

export default {
  name: "Modifier Option",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialLoading,
    MaterialSnackbar,
    MaterialCheckbox,
    Multiselect,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    categoryId: {
      type: Number,
      default: 0,
    },
    isEditOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: null,
      message: "",
      loaderActive: false,
      option: {
        id: 0,
        set_id: 0,
        category_id: this.categoryId,
        name: "",
        pos_name: "",
        price_list_id: "",
        item_max_qty: "",
        min_qty: "",
        max_qty: "",
        dining_option: 1,
        is_remark: 0,
        is_required: 0,
        applied_modifier_group_id: "c_" + this.categoryId,
      },
      diningOptionDataList: [
        { label: "All", value: 1 },
        { label: "Dine in", value: 2 },
        { label: "Takeaway", value: 3 },
      ],
      fieldRequired: {},
      priceList: [],
      type: 1, // Add
      attachedToItem: [],
      attachedToItemRemove: [],
    };
  },
  methods: {
    changeTab(type) {
      this.type = type;
      $("#general-tab .nav-link").removeClass("active");
      $("#nav_item_" + type).addClass("active");

      if (type == 2) {
        this.getAttachedToItem();
        $(".modal-option").css("min-width", "80%");
      } else {
        $(".modal-option").css("min-width", "unset");
      }
    },
    async getPriceList() {
      this.showLoader();
      var dataForm = {
        price_list: 1,
      };

      await SettingGeneralService.masterData(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.priceList = response.data.data.price_list;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    optionDetail() {
      this.showLoader();
      ModifierOptionGroupService.detail(
        this.isEditOption,
        this.categoryId,
        1
      ).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.option = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    saveOption() {
      if (this.processValidate()) {
        this.showLoader();
        this.option.pos_name = this.option.name;
        this.option.attached_to_item_remove = this.attachedToItemRemove;
        this.option.type = 1;

        ModifierOptionGroupService.store(this.option).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.$emit(
                "reload-list-option",
                this.isEditOption
                  ? "Update Option Success"
                  : "Add Option Success"
              );
              $("#hide-modal-add-option").click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },
    getAttachedToItem() {
      this.attachedToItemRemove = [];
      this.attachedToItem = [];
      if (this.option.id > 0) {
        this.showLoader();
        var data = {
          modifier_option_group_section_id: this.option.id,
        };

        ModifierOptionGroupService.getAttachedToItem(data).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.attachedToItem = response.data.data;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      }
    },
    removeItem(index, id) {
      this.attachedToItemRemove.push(id);
      this.attachedToItem.splice(index, 1);
    },
    addItem() {
      $('#hide-modal-add-option').click();
      this.$emit('attached-item', this.isEditOption, 1, this.option);
    },
    resetRequired() {
      this.fieldRequired.isOptionGroupNameRequired = false;
      this.fieldRequired.isOptionPriceRequired = false;
      this.fieldRequired.isOptionItemMaxQtyRequired = false;
      this.fieldRequired.isOptionMaxQtyRequired = false;
    },
    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.option.name == "" || this.option.name == null) {
        this.fieldRequired.isOptionGroupNameRequired = true;
        isValid = false;
      }

      if (
        this.option.price_list_id == "" ||
        this.option.price_list_id == null
      ) {
        this.fieldRequired.isOptionPriceRequired = true;
        isValid = false;
      }

      if (this.option.item_max_qty == "") {
        this.fieldRequired.isOptionItemMaxQtyRequired = true;
        isValid = false;
      }

      if (this.option.max_qty === "") {
        this.fieldRequired.isOptionMaxQtyRequired = true;
        isValid = false;
      }

      return isValid;
    },
    clearData() {
      this.resetRequired();
      this.type = 1;
      this.option = {
        id: 0,
        set_id: 0,
        category_id: this.categoryId,
        name: "",
        pos_name: "",
        price_list_id: "",
        item_max_qty: "",
        min_qty: "",
        max_qty: "",
        dining_option: 1,
        is_remark: 0,
        is_required: 0,
        applied_modifier_group_id: "c_" + this.categoryId,
      };
      this.attachedToItem = [];
      this.attachedToItemRemove = [];
      $(".modal-option").css("min-width", "unset");
      $("#general-tab #nav_item_2").removeClass("active");
      $("#nav_item_1").addClass("active");
    },
    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.clearData();
      this.getPriceList();

      if (this.isEditOption) {
        this.optionDetail();
      }
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 50px;
}

#general-tab {
  border-bottom: none;
}

#general-tab .nav-item {
  width: fit-content;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
}

#general-tab li a.active,
#general-tab li a.nav-link:hover {
    border: none !important;
    border-bottom: 5px solid #4853EB !important;
    color: #4853EB !important;
    background-color: unset;
}

.list-item {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.item-css {
  position: relative;
  display: flex;
  border: 1px solid #d2d2d2;
  margin-top: 12px;
  border-radius: 6px;
  padding: 30px 30px 10px 10px;
  width: calc(25% - 10px);
  min-height: 90px;
}

.item-css > i {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.item-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
</style>
