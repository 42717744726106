<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">TOP UP</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
        <material-button @click="addTopUp()" :disabled="disableBtnSubmit" class="mx-2 btn-background btn-sm">
          Add Top Up Amount
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="notifications">
            <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
              {{ error }}
            </material-alert>
          </div>
          <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
            <div class="row mt-4">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder w-5">
                              Topup Name
                            </th>
                            <!-- <th class="text-center font-weight-bolder w-5">
                              Total to Add
                            </th> -->
                            <th class="text-center font-weight-bolder w-20">
                              {{ $t('Payment_Amount') }} $
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('Paid_Amount_Expiry') }}
                            </th>
                            <th class="text-center font-weight-bolder w-20">
                              {{ $t('Bonus_Amount') }} $
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('Bonus_Expiry') }}
                            </th>
                            <th class="text-center font-weight-bolder w-10">
                              {{ $t('action') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="topUps.length">
                          <tr v-for="(value, key) in topUps" :key="key">
                            <td class="text-center text-xss">
                              {{ value.topup_name }}
                            </td>
                            <!-- <td class="text-center text-xss">
                              {{ value.topup_amount_format }}
                            </td> -->
                            <td class="text-left text-xss">
                              {{ value.payment_amount_format }}
                            </td>
                            <td class="text-left text-xss">
                              {{ value.paid_amount_expiry_text }}
                            </td>
                            <td class="text-left text-xss">
                              {{ value.bonus_amount_format }}
                            </td>
                            <td class="text-left text-xss">
                              {{ value.bonus_expiry_text }}
                            </td>
                            <td class="align-middle text-center text-sm">
                              <a class="btn btn-link text-dark px-3 mb-0" @click="editTopUp(value.id)">
                                <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i>Edit
                              </a>
                              <a class="
                                        btn btn-link
                                        text-danger text-gradient
                                        px-3
                                        mb-0
                                      " data-bs-toggle="modal" data-bs-target="#modalConfirm" href="javascript:;"
                                @click="confirmDelete(value.id)">
                                <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Remove
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="5" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination v-if="pagination.last_page > 1" :pagination="pagination"
                    :current_page="pagination.current_page" :last_page="pagination.last_page" />
                  <div class="text-center mt-2 d-flex justify-content-end">
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1" style="margin-top: 13px;"
                        data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                        {{ $t('download') }}
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                          <a class="dropdown-item" @click="exportFile('csv')" href="#">Export CSV</a>
                        </li>
                        <li>
                          <a class="dropdown-item" @click="exportFile('xlsx')" href="#">Export Excel</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="mt-5 row">
                    <div class="col-lg-12 col-md-12 col-12 mx-auto" style="white-space: pre-wrap !important;">
                      <div class="ps-0 label-form">Terms & Conditions</div>
                      <ckeditor
                        :editor="editor"
                        v-model="terms_conditions"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                  </div>
                  <div class="text-center mt-4 d-flex justify-content-end">
                    <material-button @click="saveTermsConditions()" class="btn-background btn-sm">
                      Save
                    </material-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm message="Are you sure you want to delete ?" :process-yes="processYes" />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date="" :description="message"
          :icon="{ component: 'campaign', color: 'white' }" :color="snackbar" :close-handler="closeSnackbar" />
      </div>
      <material-loading :active="loaderActive" />
      <form :action="`${actionDownload}/merchant/download-topups`" method="POST">
        <input type="hidden" name="merchantId" :value="merchantIdExport" />
        <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
        <input type="hidden" name="token" :value="token" />
        <input type="hidden" name="action_export" :value="action_export" />
        <input type="hidden" name="action" value="export" />
        <input id="downloadForm" type="submit" class="d-none" />
      </form>

      <div>
        <button style="display: none" type="button" id="action-group" data-bs-toggle="modal"
          data-bs-target="#modalEdit"></button>
        <div class="modal fade" id="modalEdit" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ labelGroup }}</h5>
              </div>
              <div class="modal-body">
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">
                    Topup Name<span class="text-danger ms-2">*</span>
                  </div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end">
                    <material-input v-model="dataTopUp.topup_name" extraClass="ml-5"
                      placeholder="Enter topup name"/>
                  </div>
                </div>
                <div class="row d-flex bd-highlight" v-if="fieldRequired.isTopupNameRequire">
                  <div class="col-lg-4 col-4 align-self-center">
                  </div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight">
                    <span class="is-invalid">{{ textRequireTopupAmountCustom }}</span>
                  </div>
                </div>
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">
                    Payment Amount<span class="text-danger ms-2">*</span>
                  </div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end">
                    <material-input v-model="dataTopUp.payment_amount" extraClass="ml-5"
                      placeholder="Enter payment amount" type="number" @input="amountChange()"/>
                  </div>
                </div>
                <div class="row d-flex bd-highlight" v-if="fieldRequired.isPaymentAmountRequire">
                  <div class="col-lg-4 col-4 align-self-center">
                  </div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight">
                    <span class="is-invalid">{{ textRequirePaymentAmountCustom }}</span>
                  </div>
                </div>
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">
                    Bonus Amount
                  </div>
                  <div class="col-lg-8 col-8 d-flex align-items-center" style="color: #50565D !important; font-size: 14px;">
                    <material-input v-model="dataTopUp.bonus_amount" extraClass="ml-5" placeholder="Enter bonus amount"
                      type="number" @input="amountChange()" />
                  </div>
                </div>
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">
                    Total to Add<span class="text-danger ms-2">*</span>
                  </div>
                  <div class="col-lg-8 col-8 d-flex align-items-center" style="color: #50565D !important; font-size: 14px;">
                    {{ dataTopUp.topup_amount }}
                  </div>
                </div>
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">
                    Paid Amount Expiry<span class="text-danger ms-2">*</span>
                  </div>
                  <div class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end pe-5">
                    <material-input v-model="dataTopUp.paid_amount_expiry_number" extraClass="ml-5"
                      placeholder="Number" type="number" />
                  </div>
                  <div class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end">
                    <Multiselect placeholder="Select Time" :canClear="false" :options="timeUnits"
                      v-model="dataTopUp.paid_amount_expiry_unit" />
                  </div>
                  <div class="col-lg-2 col-2 d-flex bd-highlight d-flex justify-content-center align-items-center r-no-expiry">
                    <material-checkbox
                        v-model="dataTopUp.noExpiryPaidAmount"
                        :checked="dataTopUp.noExpiryPaidAmount"
                        name="no_expiry_paid_amount"
                        id="no_expiry_paid_amount"
                        class="checkbox-custom"
                      >
                        <template v-slot:label
                          >No Expiry</template
                        ></material-checkbox
                      >
                  </div>
                </div>
                <div class="row d-flex bd-highlight" v-if="fieldRequired.isExpiryRequire">
                  <div class="col-lg-4 col-4 align-self-center">
                  </div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight">
                    <span class="is-invalid">{{ textRequireExpiryCustom }}</span>
                  </div>
                </div>
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">
                    Bonus Expiry<span class="text-danger ms-2">*</span>
                  </div>
                  <div class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end pe-5">
                    <material-input v-model="dataTopUp.bonus_expiry_number" extraClass="ml-5"
                      placeholder="Number" type="number" />
                  </div>
                  <div class="col-lg-3 col-3 d-flex bd-highlight d-flex justify-content-end">
                    <Multiselect placeholder="Select Time" :canClear="false" :options="timeUnits"
                      v-model="dataTopUp.bonus_expiry_unit" />
                  </div>
                  <div class="col-lg-2 col-2 d-flex bd-highlight d-flex justify-content-center align-items-center r-no-expiry">
                    <material-checkbox
                        v-model="dataTopUp.noExpiryBonus"
                        :checked="dataTopUp.noExpiryBonus"
                        name="no_expiry_bonus"
                        id="no_expiry_bonus"
                        class="checkbox-custom"
                      >
                        <template v-slot:label
                          >No Expiry</template
                        ></material-checkbox
                      >
                  </div>
                </div>
                <div class="row d-flex bd-highlight" v-if="fieldRequired.isExpiryBonusRequire">
                  <div class="col-lg-4 col-4 align-self-center">
                  </div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight">
                    <span class="is-invalid">{{ textRequireExpiryBonusCustom }}</span>
                  </div>
                </div>
              </div>
              <!-- Modal content-->
              <div class="modal-footer">
                <button type="button" class="btn btn-success btn-background btn-sm" @click="saveTopup">
                  Save
                </button>
                <button type="button" class="btn btn-primary btn-close-modal btn-sm" data-bs-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import TopUpService from "../../../services/settingTopUp.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialInput from "@/components/MaterialInput.vue";
import $ from "jquery";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import TokenService from "../../../services/token.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "find-store",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirm,
    Pagination,
    MaterialInput,
    Multiselect,
    MaterialCheckbox,
  },
  data() {
    return {
      nameIsRequired: false,
      message: "",
      snackbar: null,
      loaderActive: false,
      topUps: [],
      pagination: {},
      page: 1,
      topUpId: null,
      isLoadData: false,
      labelGroup: "ADD NEW TOP UP AMOUNT",
      terms_conditions: "",
      dataTopUp: {
        id: 0,
        topup_name: "",
        topup_amount: 0,
        payment_amount: 0,
        bonus_amount: 0,
        paid_amount_expiry_number: "",
        paid_amount_expiry_unit: 1,
        bonus_expiry_number: "",
        bonus_expiry_unit: 1,
        noExpiryPaidAmount: false,
        noExpiryBonus: false,
      },
      timeUnits: [
        {
          value: 1,
          label: 'days'
        },
        {
          value: 2,
          label: 'months'
        },
        {
          value: 3,
          label: 'years'
        },
      ],
      fieldRequired: {},
      textRequireTopupAmountCustom: "",
      textRequirePaymentAmountCustom: "",
      textRequireExpiryCustom: "",
      textRequireExpiryBonusCustom: "",
      action_export: 'csv',
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo'
          ]
        },
      },
    };
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getList(this.page);
  },
  methods: {
    exportFile(type = 'csv') {
      this.action_export = type;
      setTimeout(() => {
        $('#downloadForm').click();
      }, 150);
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    amountChange() {
      var payment_amount = this.dataTopUp.payment_amount ? parseFloat(this.dataTopUp.payment_amount) : 0;
      var bonus_amount = this.dataTopUp.bonus_amount ? parseFloat(this.dataTopUp.bonus_amount) : 0;
      this.dataTopUp.topup_amount = parseFloat(payment_amount + bonus_amount).toFixed(2);
    },

    getList(page) {
      this.isLoadData = false;
      this.topUps = [];
      this.showLoader();
      var dataForm = {
        page: page,
      };
      TopUpService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.topUps = response.data.data.topupList.items;
            this.pagination = response.data.data.topupList;
            this.page = this.pagination.current_page;
            this.terms_conditions = response.data.data.termsConditions;
            this.$router.push({
              query: Object.assign({}, this.$route.query, { page: this.page }),
            });
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    saveTopup() {
      if (this.processValidate()) {
        if (!this.dataTopUp.id) {
          TopUpService.store(this.dataTopUp).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Save Top Up Success";
                this.setTimeOutNotification();
                $(".btn-close-modal").click();
                this.page = 1
                this.getList(this.page);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          TopUpService.update(this.dataTopUp).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update Top Up Success";
                this.setTimeOutNotification();
                $(".btn-close-modal").click();
                this.getList(this.page);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    saveTermsConditions() {
      this.showLoader();
      let dataForm = {
        terms_conditions: this.terms_conditions
      }

      TopUpService.saveTermsConditions(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Update Terms & Conditions Success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    resetRequired() {
      this.fieldRequired.isTopupNameRequire = false;
      this.fieldRequired.isPaymentAmountRequire = false;
      this.fieldRequired.isExpiryRequire = false;
      this.fieldRequired.isExpiryBonusRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.dataTopUp.topup_name == "" || this.dataTopUp.topup_name == null) {
        this.fieldRequired.isTopupNameRequire = true;
        this.textRequireTopupAmountCustom = "The field is required."
        isValid = false;
      } 
      // else if(this.dataTopUp.topup_amount && !this.isPositiveInteger(this.dataTopUp.topup_amount)) {
      //   this.fieldRequired.isTopupAmountRequire = true;
      //   this.textRequireTopupAmountCustom = "You must enter a positive."
      //   isValid = false;
      // } else if(this.dataTopUp.topup_amount && this.dataTopUp.topup_amount.length > 7) {
      //   this.fieldRequired.isTopupAmountRequire = true;
      //   this.textRequireTopupAmountCustom = "You can only enter up to 7 numeric characters."
      //   isValid = false;
      // }
      if (this.dataTopUp.payment_amount == "" || this.dataTopUp.payment_amount == null) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom = "The field is required."
        isValid = false;
      } else if(this.dataTopUp.payment_amount && !this.isPositiveInteger(this.dataTopUp.payment_amount)) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom = "You must enter a positive."
        isValid = false;
      } else if(this.dataTopUp.payment_amount && this.dataTopUp.payment_amount.length > 7) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom = "You can only enter up to 7 numeric characters."
        isValid = false;
      } else if(this.dataTopUp.topup_amount && this.dataTopUp.payment_amount && (parseInt(this.dataTopUp.topup_amount) < parseInt(this.dataTopUp.payment_amount))) {
        this.fieldRequired.isPaymentAmountRequire = true;
        this.textRequirePaymentAmountCustom = "You must enter top up amount greater than or equal to payment amount."
        isValid = false;
      }

      if (!this.dataTopUp.noExpiryPaidAmount) {
        if (this.dataTopUp.paid_amount_expiry_number == "" || this.dataTopUp.paid_amount_expiry_number == null) {
          this.fieldRequired.isExpiryRequire = true;
          this.textRequireExpiryCustom = "The field is required."
          isValid = false;
        } else if (this.dataTopUp.paid_amount_expiry_number && !this.isPositiveInteger(this.dataTopUp.paid_amount_expiry_number)) {
          this.fieldRequired.isExpiryRequire = true;
          this.textRequireExpiryCustom = "You must enter a positive."
          isValid = false;
        } else if (this.dataTopUp.paid_amount_expiry_unit == null || this.dataTopUp.paid_amount_expiry_unit == '' || this.dataTopUp.paid_amount_expiry_unit == 0) {
          this.fieldRequired.isExpiryRequire = true;
          this.textRequireExpiryCustom = "You must choose a time unit."
          isValid = false;
        }
      }
      if (!this.dataTopUp.noExpiryBonus) {
        if (this.dataTopUp.bonus_expiry_number == "" || this.dataTopUp.bonus_expiry_number == null) {
          this.fieldRequired.isExpiryBonusRequire = true;
          this.textRequireExpiryBonusCustom = "The field is required."
          isValid = false;
        } else if (this.dataTopUp.bonus_expiry_number && !this.isPositiveInteger(this.dataTopUp.bonus_expiry_number)) {
          this.fieldRequired.isExpiryBonusRequire = true;
          this.textRequireExpiryBonusCustom = "You must enter a positive."
          isValid = false;
        } else if (this.dataTopUp.bonus_expiry_unit == null || this.dataTopUp.bonus_expiry_unit == '' || this.dataTopUp.bonus_expiry_unit == 0) {
          this.fieldRequired.isExpiryBonusRequire = true;
          this.textRequireExpiryBonusCustom = "You must choose a time unit."
          isValid = false;
        }
      }
      return isValid;
    },

    isPositiveInteger(str) {
      return parseFloat(str) > 0
    },

    addTopUp() {
      this.labelGroup = "ADD NEW TOP UP AMOUNT";
      this.dataTopUp.id = 0;
      this.dataTopUp.topup_name = "";
      this.dataTopUp.topup_amount = 0;
      this.dataTopUp.payment_amount = 0;
      this.dataTopUp.bonus_amount = 0;
      this.dataTopUp.paid_amount_expiry_number = "";
      this.dataTopUp.paid_amount_expiry_unit = 1;
      this.dataTopUp.bonus_expiry_number = "";
      this.dataTopUp.bonus_expiry_unit = 1;
      this.dataTopUp.noExpiryPaidAmount = false;
      this.dataTopUp.noExpiryBonus = false;
      const modalAdd = document.getElementById("action-group");
      modalAdd.click();
      this.resetRequired()
    },

    async editTopUp(topUpId) {
      this.resetRequired()
      this.isLoadDoneData = false;
      this.showLoader();
      await TopUpService.detail(topUpId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            const topUp = response.data.data;
            this.isLoadDoneData = true;
            this.dataTopUp.id = topUp.id ?? 0;
            this.dataTopUp.topup_name = topUp.topup_name ?? "";
            this.dataTopUp.topup_amount = topUp.topup_amount ?? 0;
            this.dataTopUp.payment_amount = topUp.payment_amount ?? 0;
            this.dataTopUp.bonus_amount = topUp.bonus_amount ?? 0;
            this.dataTopUp.paid_amount_expiry_number = topUp.paid_amount_expiry_number ?? "";
            this.dataTopUp.paid_amount_expiry_unit = topUp.paid_amount_expiry_unit ?? 1;
            this.dataTopUp.bonus_expiry_number = topUp.bonus_expiry_number ?? "";
            this.dataTopUp.bonus_expiry_unit = topUp.bonus_expiry_unit ?? 1;
            this.dataTopUp.noExpiryPaidAmount = topUp.paid_amount_expiry_number ? false : true;
            this.dataTopUp.noExpiryBonus = topUp.bonus_expiry_number ? false : true;
            this.labelGroup = `EDIT TOP UP AMOUNT`;
            const editTopUp = document.getElementById("action-group");
            editTopUp.click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    processYes() {
      $(".btn-close-modal").click();
      this.showLoader();
      TopUpService.delete(this.topUpId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            if (this.topUps.length == 1) {
              this.page = this.page - 1;
            }
            this.getList(this.page);
            this.snackbar = "success";
            this.message = "Delete top up success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    confirmDelete(topUpId) {
      this.topUpId = topUpId;
      $(".btn-confirm-modal").click();
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.text-title {
  color: #133067 !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.text-label {
  color: #5b848d !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.checkbox-auto-save label {
  padding-top: 11px !important;
}

.custom-name {
  word-break: break-all;
  max-width: 200px;
  white-space: normal;
}

.wrap-action .form-check:first-child {
  padding-left: 2px;
}

.multiselect {
  min-width: 135px;
}

.r-no-expiry .form-check {
  padding-left: 0 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50%;
    margin: 1.75rem auto;
  }
}
</style>