<template>
  <div>
    <div class="row mt-3">
      <div class="col-lg-3 col-md-12 col-12">
        <div class="form-group">
          <label> Branch </label>
          <div>
            <Multiselect
              v-model="branch_id"
              :options="branchs"
              placeholder="Select Branch"
              @update:model-value="
                onChangeSearchParameter(branch_id, search_field.BRANCH_ID)
              "
            />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 col-12">
        <div class="form-group">
          <label> From </label>
          <div>
            <Datepicker
              :enableTimePicker="false"
              class="date-time-dashboad"
              v-model="from_date"
              auto-apply
              :format="format"
              @update:model-value="
                onChangeSearchParameter(from_date, search_field.FROM_DATE)
              "
            />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 col-12">
        <div class="form-group">
          <label> To </label>
          <div>
            <Datepicker
              :enableTimePicker="false"
              class="date-time-dashboad"
              v-model="to_date"
              auto-apply
              :format="format"
              @update:model-value="
                onChangeSearchParameter(to_date, search_field.TO_DATE)
              "
            />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 col-12 d-flex align-items-end">
        <div class="form-group">
          <label></label>
          <div>
            <material-button
              style="min-width: unset"
              class="btn-background btn-sm btn-run mb-1 px-2"
              @click="reloadData()"
            >
              Run
            </material-button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-4 export-type">
      <button class="dt-button buttons-html5" type="button" @click="exportFile('csv')"><span>Csv</span></button>
      <button class="dt-button buttons-html5" type="button" @click="exportFile('xlsx')"><span>Excel</span></button>
      <button class="dt-button buttons-html5" type="button" @click="exportFile('pdf')"><span>PDF</span></button>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import CommonFunctionService from "../../../services/commonFunction.service";
import moment from "moment";
import { startOfMonth } from "date-fns";
import { SEARCH_FIELD } from "../mock_data";
import DashboardReportService from "../../../services/dashboardReport.service";

export default {
  name: "FormSearch",
  components: {
    MaterialButton,
    Multiselect,
    Datepicker,
  },
  props: {
    searchParameter: {
      type: Object,
      required: true,
    },
    onChangeSearchParameter: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.getDataFilter();
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      branchs: [],
      branch_id: -1,
      from_date: moment(startOfMonth(new Date())),
      to_date: new Date(),
      search_field: SEARCH_FIELD,
    };
  },
  methods: {
    format(date) {
      return CommonFunctionService.formatDateShow(date);
    },

    getDataFilter() {
      this.showLoader();
      DashboardReportService.getFilterDataTopupReport().then(
        (response) => {
          if (response.data.result.isSuccess) {
            var DataFilter = response.data.data;
            this.branchs = DataFilter.branches;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    reloadData() {
      this.$emit("reload-data");
    },

    exportFile(type) {
      this.$emit("export", type);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>

<style scoped>
label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 700;
}

.btn-run {
  color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-run:hover {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-run:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5) !important;
}

.export-type {
  position: absolute;
}

div.dt-button-info {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    margin-top: -100px;
    margin-left: -200px;
    background-color: white;
    border: 2px solid #111;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    text-align: center;
    z-index: 21;
}
div.dt-button-info h2 {
    padding: 0.5em;
    margin: 0;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    background-color: #f3f3f3;
}
div.dt-button-info > div {
    padding: 1em;
}
div.dt-button-collection-title {
    text-align: center;
    padding: 0.3em 0 0.5em;
    font-size: 0.9em;
}
div.dt-button-collection-title:empty {
    display: none;
}
button.dt-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin-right: 0.333em;
    margin-bottom: 0.333em;
    padding: 0.5em 1em;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    cursor: pointer;
    font-size: 0.88em;
    line-height: 1.6em;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -moz-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -o-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='rgba(230, 230, 230, 0.1)', EndColorStr='rgba(0, 0, 0, 0.1)');
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    outline: none;
    text-overflow: ellipsis;
}
button.dt-button.disabled {
    cursor: default;
    opacity: 0.4;
}
button.dt-button:active:not(.disabled),
button.dt-button.active:not(.disabled) {
    background-color: rgba(0, 0, 0, 0.1);
    background: -webkit-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -moz-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -o-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='rgba(179, 179, 179, 0.1)', EndColorStr='rgba(0, 0, 0, 0.1)');
    box-shadow: inset 1px 1px 3px #999999;
}
button.dt-button:active:not(.disabled):hover:not(.disabled),
button.dt-button.active:not(.disabled):hover:not(.disabled) {
    box-shadow: inset 1px 1px 3px #999999;
    background-color: rgba(0, 0, 0, 0.1);
    background: -webkit-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -moz-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -o-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='rgba(128, 128, 128, 0.1)', EndColorStr='rgba(0, 0, 0, 0.1)');
}
button.dt-button:hover {
    text-decoration: none;
}
button.dt-button:hover:not(.disabled) {
    border: 1px solid #666;
    background-color: rgba(0, 0, 0, 0.1);
    background: -webkit-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -moz-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -o-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='rgba(153, 153, 153, 0.1)', EndColorStr='rgba(0, 0, 0, 0.1)');
}
button.dt-button:focus:not(.disabled) {
    border: 1px solid #426c9e;
    text-shadow: 0 1px 0 #c4def1;
    outline: none;
    background-color: #79ace9;
    background: -webkit-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
    background: -moz-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
    background: -ms-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
    background: -o-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
    background: linear-gradient(to bottom, #d1e2f7 0%, #79ace9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#d1e2f7', EndColorStr='#79ace9');
}
div.dt-button-collection button.dt-button {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    display: block;
    float: none;
    margin-bottom: 4px;
    margin-right: 0;
}
div.dt-button-collection button.dt-button:active:not(.disabled),
div.dt-button-collection button.dt-button.active:not(.disabled) {
    background-color: #dadada;
    background: -webkit-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
    background: -moz-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
    background: -ms-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
    background: -o-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
    background: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#f0f0f0', EndColorStr='#dadada');
    box-shadow: inset 1px 1px 3px #666;
}
button.dt-button.processing {
    color: rgba(0, 0, 0, 0.2);
}
button.dt-button.processing:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin: -8px 0 0 -8px;
    box-sizing: border-box;
    display: block;
    content: " ";
    border: 2px solid #282828;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: dtb-spinner 1500ms infinite linear;
    -o-animation: dtb-spinner 1500ms infinite linear;
    -ms-animation: dtb-spinner 1500ms infinite linear;
    -webkit-animation: dtb-spinner 1500ms infinite linear;
    -moz-animation: dtb-spinner 1500ms infinite linear;
}
</style>
