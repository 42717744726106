<template>
    <div>
      <div class="row mb-2 pe-4">
        <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
          <div class="color-common">
            <h3 class="title-page mb-0 ps-3">Item List</h3>
          </div>
        </div>
      </div>
      <div class="page-header align-items-start min-vh-100 my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 mx-auto">
              <div class="d-flex justify-content-end mt-5">
                <material-button
                  class="mx-2 btn btn-sm btn-background"
                  @click="redirectToItemListByBranch()"
                >
                  GO TO ITEM QUICK MANAGEMENT
                </material-button>
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true" :disabled="disabled" style="height: 100%;">
                    {{ $t('download') }}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a class="dropdown-item" @click="exportFile('csv')" href="#">Export CSV</a>
                    </li>
                    <li>
                      <a class="dropdown-item" @click="exportFile('excel')" href="#">Export Excel</a>
                    </li>
                    <li>
                      <a class="dropdown-item" @click="exportFile('json')" href="#">Export Json</a>
                    </li>
                  </ul>
                </div>
                <material-button
                  class="mx-2 btn btn-sm btn-background"
                  @click="handleEnableOrDisableAll(true)"
                >
                  Disable
                </material-button>
                <material-button
                  class="mx-2 btn btn-sm btn-background"
                  @click="handleEnableOrDisableAll(false)"
                >
                  Enable
                </material-button>
              </div>
                <div class="row mt-3" style="align-items: center;border-bottom: 1px solid #d2d2d2;">
                    <div class="col-lg-11 col-md-11 col-11">
                        <input
                          class="nosubmit"
                          type="search"
                          id="keyword_search"
                          extraClass
                          name="keyword_search"
                          placeholder="Search item name ..."
                          v-model="item_name"
                        />
                    </div>
                    <div class="col-lg-1 col-md-1 col-1" v-if="this.item_name != '' && this.is_search == true">
                        Count {{ pagination.total }}
                    </div>
                    <div class="col-lg-1 col-md-1 col-1" v-else>
                        Count 0
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-3 col-md-3 col-3">
                      <Multiselect v-model="menu_set_id" :options="menuSetDropdown" :canClear="false" />
                    </div>
                    <div class="col-lg-3 col-md-3 col-3">
                      <Multiselect v-model="type_id" :options="typeDropdown" :canClear="false" />
                    </div>
                    <div class="col-lg-3 col-md-3 col-3">
                      <Multiselect v-model="status_id" :options="statusDropdown" :canClear="false" />
                    </div>
                    <div class="col-lg-3 col-md-3 col-3">
                      <material-button
                        class="mx-2 btn btn-sm btn-background"
                        @click="searchItemName()"
                      >
                        Search
                      </material-button>
                    </div>
                </div>
              <div class="row mt-3">
                <div class="col-lg-6 col-12 d-flex align-items-center mb-2">
                  <div class="label-header">
                    Showing {{ pagination.number_items }} of {{ pagination.total }} Items
                  </div>
                </div>
                <div class="col-12" v-if="isLoadData">
                  <div class="card-custom">
                    <div class="px-0 pb-2">
                      <div class="table-responsive p-0 custom-table">
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th class="text-center font-weight-bolder w-5">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :checked="isAllSelected" @change="selectAll">
                                </div>
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Menu set") }}
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Item Code") }}
                              </th>
                              <th class="text-center font-weight-bolder">
                                {{ $t("Type") }}
                              </th>
                              <th class="text-center font-weight-bolder w-10">
                                {{ $t("Image") }}
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Item Name") }}
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Description") }}
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Variables") }}
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Price") }}
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Tags & Allergents") }}
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Status") }}
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Branch") }}
                              </th>
                              <th class="text-center font-weight-bolder w-5">
                                {{ $t("Action") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="itemList.length">
                            <tr v-for="(value, key) in itemList" :key="key">
                                <td
                                    class="text-center text-xss w-25"
                                    >
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :value="value.id" v-model="selectedItems">
                                    </div>
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    {{ value.menuset_name }}
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    {{ value.item_no }}
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    {{ value.item_type_format }}
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    <img :src="value.image" alt="Image" width="70" height="70">
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    {{ value.item_name }}
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    {{ value.description }}
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    {{value.item_price_merchant 
                                      && value.item_price_merchant.length > 0 
                                      ? (value.item_price_merchant && value.item_price_merchant.length > 0 ? value.item_price_merchant.map(item => item.name).join(", ") : "")
                                      : ""
                                    }}
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    {{ value.item_price_format }}
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    
                                </td>
                                <td class="text-left text-xss w-25" v-if="value.is_disabled == 0" style="color: green">
                                    Active
                                </td>
                                <td class="text-left text-xss w-25" v-else style="color: red">
                                    Disable
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    
                                </td>
                                <td
                                    class="text-left text-xss w-25"
                                    >
                                    <material-button
                                        class="mx-2 btn btn-sm btn-background"
                                        @click="handleEdit(value)"
                                        >
                                        Edit
                                    </material-button>
                                    <material-button
                                        v-if="value.is_disabled == 0"
                                        class="mx-2 btn btn-sm btn-background"
                                        @click="handleEnableOrDisable(value)"
                                        >
                                        Disable
                                    </material-button>
                                    <material-button
                                        v-else
                                        class="mx-2 btn btn-sm btn-background"
                                        @click="handleEnableOrDisable(value)"
                                        >
                                        Enable
                                    </material-button>
                                </td>

                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="10" class="text-center">No item available</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <pagination
                      v-if="pagination.last_page > 1"
                      :pagination="pagination"
                      :current_page="pagination.current_page"
                      :last_page="pagination.last_page"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
        <material-loading :active="loaderActive" />
        <ModalConfirmDelete
          message="Are you sure you want to delete ?"
          :process-yes="deleteItemList"
        />
      </div>
      <form :action="`${actionDownload}/merchant/item`" method="GET">
          <input type="hidden" name="merchant_code" :value="merchantCode" />
          <input type="hidden" name="token" :value="token" />
          <input type="hidden" name="action_export" :value="action_export" />
          <input type="hidden" name="action" value="export" />
          <input type="hidden" name="selectedItems" :value="selectedItems" />
          <input id="downloadForm" type="submit" class="d-none" />
      </form>
    </div>
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import Pagination from "@/components/Pagination.vue";
  import "bootstrap/js/dist/modal";
  import MaterialButton from "@/components/MaterialButton.vue";
  import "@vuepic/vue-datepicker/dist/main.css";
  import "@vueform/multiselect/themes/default.css";
  import ItemService from "../../services/product/item.service";
  import Multiselect from "@vueform/multiselect";
  import TokenService from "../../services/token.service";
  import $ from "jquery";
  import { saveAs } from 'file-saver';
  import ModalConfirmDelete from "@/components/ModalConfirmDelete.vue";
  
  export default {
    name: "campaign-list",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      MaterialButton,
      Pagination,
      Multiselect,
      ModalConfirmDelete,
    },
    data() {
      return {
        token: TokenService.getLocalAccessToken(),
        actionDownload: process.env.VUE_APP_BASE_URL,
        message: "",
        snackbar: null,
        loaderActive: false,
        pagination: {},
        page: 1,
        isLoadData: false,
        itemList: [],
        filterListData: [
            {value: 1, label: "Select Menu Set"}
        ],
        allSelected: false,
        selectedItems: [],
        item_name: "",
        is_search: false,
        menuSetDropdown: [],
        typeDropdown: [
          {value: 0, label: "Select Type"},
          {value: 1, label: "Main"},
          {value: 2, label: "Modifier"},
        ],
        statusDropdown: [
          {value: -1, label: "Select Status"},
          {value: 0, label: "Active"},
          {value: 1, label: "Disable"},
        ],
        menu_set_id: 0,
        merchantCode: "",
        merchantName: "",
        action: 'list',
        action_export: 'excel',
        type_id: 0,
        status_id: -1,
        itemIdToDelete: 0
      };
    },
    mounted() {
      if (this.$route.query.page) {
        this.page = this.$route.query.page;
      }
      const code = localStorage.getItem("MERCHANT_CODE", "");
      const name = localStorage.getItem("MERCHANT_ADMIN_NAME", "");
      this.merchantCode = code;
      this.merchantName = name ? name : "";
      this.getList(this.page);
      this.getMenuSetDropdown();
    },
    methods: {
      exportFile(type = 'csv') {
        if (this.selectedItems.length == 0) {
          this.snackbar = "danger";
          this.message = "You must select least 1 column to export file."
          this.setTimeOutNotification();
        } else {
          if(type == 'json') {
            this.exportData()
          } else {
            this.action_export = type;
            setTimeout(() => {
              $('#downloadForm').click();
            }, 150);
          }
        }
      },
      selectAll(event) {
          if (event.target.checked) {
          this.selectedItems = this.itemList.map(item => item.id)
          } else {
          this.selectedItems = []
          }
      },
      closeSnackbar() {
        this.snackbar = null;
      },
  
      setTimeOutNotification() {
        setTimeout(() => {
          this.snackbar = null;
        }, 3000);
      },
  
      getList(page) {
        this.isLoadData = false;
        this.showLoader();
        var dataForm = {
          page: page,
        };
        ItemService.getList(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.itemList = response.data.data.items;
              this.menuSetDropdown = response.data.data.menu_set_array;
              this.pagination = response.data.data;
              this.page = this.pagination.current_page;
              this.isLoadData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
        this.hideLoader();
      },

      getMenuSetDropdown() {
        this.isLoadData = false;
        this.showLoader();
        ItemService.getMenuSetDropdown().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.menuSetDropdown = response.data.data;
              this.isLoadData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
        this.hideLoader();
      },

      handleEnableOrDisable(item) {
        this.isLoadData = false;
        this.showLoader();
        var dataForm = {
          id: item.id,
          value: item.is_disabled
        };
        ItemService.handleEnableOrDisable(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
                this.getList(this.page);
                this.getMenuSetDropdown();
            } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
        this.hideLoader();
      },
  
    //   menusetDetail(id) {
    //     this.$router.push('/create-menu/' + id);
    //   },
  
      showLoader() {
        this.loaderActive = true;
      },
  
      hideLoader() {
        this.loaderActive = false;
      },

      handleEnableOrDisableAll(is_disabled) {
        if(this.selectedItems && this.selectedItems.length > 0) {
          this.isLoadData = false;
          this.showLoader();
          var dataForm = {
            is_disabled: is_disabled,
            selectedItems: this.selectedItems
          };
          ItemService.handleEnableOrDisableAll(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                  this.getList(this.page);
              } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.setTimeOutNotification();
            }
          );
          this.hideLoader();
        } else {
          this.snackbar = "danger";
          this.message = "You must select least 1 column to export file."
          this.setTimeOutNotification();
        }
      },
      searchItemName() {
        // if(this.item_name != "" || this.menu_set_id > 0 || this.type_id > 0) {
          this.isLoadData = false;
          this.showLoader();
          var dataForm = {
            page: this.page
          };
          if(this.item_name != "") {
            dataForm.search_keyword = this.item_name;
          }
          if(this.menu_set_id > 0) {
            dataForm.set_id = this.menu_set_id;
          }
          if(this.type_id > 0) {
            dataForm.item_type = this.type_id;
          }
          if(this.status_id >= 0) {
            dataForm.status = this.status_id;
          }
          ItemService.getList(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.itemList = response.data.data.items;
                this.pagination = response.data.data;
                this.page = this.pagination.current_page;
                this.isLoadData = true;
                this.is_search = true;
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.setTimeOutNotification();
            }
          );
          this.hideLoader();
        // }
      },
      exportData() {
        const newData = [];
        const selectedObjects = this.itemList.filter((obj) => this.selectedItems.includes(obj.id));

        selectedObjects.forEach((value) => {
          const dataExport = {
            "Item Code": value.item_no,
            "Type": value.item_type_format,
            "Image": value.image,
            "Item Name": value.item_name,
            "Description": value.description,
            "Variables": value.item_price_merchant && value.item_price_merchant.length > 0 && (value.item_price_merchant && value.item_price_merchant.length > 0 ? value.item_price_merchant.map(item => item.name).join(", ") : ""),
            "Price": value.item_price_format,
            "Tags & Allergents": "",
            "Status": value.is_disabled === 0 ? 'Active' : 'Disable',
            "Branch": ""
          };
          newData.push(dataExport);
        });

        const jsonData = JSON.stringify(newData, null, 2);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const fileName = 'itemList.json';
        saveAs(blob, fileName);
      },
      handleEdit(item) {
        var url = '/edit-item/';
        if (item.is_combo) {
          url = '/edit-combo/';
        }

        url += item.id + '?set_id=' + item.set_id;

        this.$router.push(url)
      },
      reloadListItem(mess) {
        this.snackbar = "success";
        this.message = mess;
        this.setTimeOutNotification();
        this.getList(this.page);
        this.getMenuSetDropdown();
      },
      openModalDeleteListItem(itemId) {
        this.itemIdToDelete = itemId;
        $(".btn-confirm-modal-delete").click();
      },
      deleteItemList() {
        this.showLoader();
        ItemService.deleteItem(this.itemIdToDelete).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.reloadListItem("Deleted item successfullyy");
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
      },
      redirectToItemListByBranch() {
        var url = '/item-list-by-branch';

        this.$router.push(url)
      }
    },
    computed: {
      isAllSelected: {
        get() {
          return this.selectedItems && this.selectedItems.length === this.itemList.length
        },
        set(value) {
          if (value) {
              this.selectedItems = this.itemList.map(item => item.id)
          } else {
              this.selectedItems = []
          }
        }
      }
    }
  };
  </script>
  <style scoped>
  .css-icon {
    position: absolute;
    left: 17px !important;
    font-size: 18px !important;
    top: 7px !important;
  }
  
  .label-header {
    font-size: 16px;
    color: #222;
    font-weight: 500;
  }

  .nosubmit-parent {
    border: none;
    padding: 0;
    }

    input.nosubmit {
    border-bottom: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat 13px center;
    }
    .dropdown:not(.dropdown-hover) .dropdown-menu.show {
      margin-top: 3.5rem !important;
    }
    .form-check {
      padding: 0px;
    }
  </style>
  