<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common d-flex align-items-center">
          <div class="btn-back d-flex align-items-center ps-0 mt-2">
            <h4 @click="backToEmailSetup()" class="cursor-pointer">
              <i class="fa fa-chevron-circle-left pe-2" aria-hidden="true"></i>
              <span>BACK</span>
            </h4>
          </div>
          <h3 class="title-page mb-0 ps-3">{{ email.id ? 'Detail' : 'Create'}} Email</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-white"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mx-auto">
          <div class="row mt-5">
            <div class="col-xl-6 col-12">
              <div class="c-email-step">
                <h3 class="title-page mb-3">Step 1</h3>
                <div class="w-100 d-flex align-items-center">
                  <div>
                    {{ 'From: ' + merchantName }}
                  </div>
                </div>
                <div class="w-100 mt-3">
                  <div>To</div>
                  <material-textarea
                    rows="3"
                    id="mail_to"
                    name="mail_to"
                    :placeholder="mailToMemberText"
                    :readOnly="true"
                  />
                </div>
                <div class="recipients-list p-3 mt-5">
                  <div v-for="(group, index) in recipientGroups" class="d-flex align-items-center mb-2" :key="index">
                    <material-checkbox
                      :value="group.id"
                      :checked="email.recipient_group_ids.includes(group.id)"
                      :id="'recipients-group-' + index"
                      class="align-items-center checkbox-custom"
                      @input="checkrecipientGroups(group, index)"
                    ></material-checkbox>
                    <span class="ms-2">{{ group.name + ' ' + group.total_member_text }}</span>
                  </div>
                  <material-button
                    @click="openModalSelectRecipients"
                    class="btn btn-sm btn-background"
                    >Select Recipients</material-button
                  >
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-12">
              <div class="p-3 c-email-step">
                <h3 class="title-page mb-3">Step 2</h3>
                <div class="w-100" :class="{'mb-3': !fieldRequired.isEmailSubjectRequire}">
                  <h5>Subject</h5>
                  <material-input
                    type="text"
                    v-model="email.subject"
                    id="email_subject"
                    name="email_subject"
                    placeholder="Your subscription is expiring soon."
                  />
                </div>
                <div class="w-100 mb-3">
                  <span
                    class="is-invalid"
                    v-if="fieldRequired.isEmailSubjectRequire"
                    >The field is required.</span
                  >
                </div>
                <div class="w-100 mb-3">
                  <h5>Body</h5>
                  <div>
                    <ckeditor
                      :editor="editor"
                      v-model="email.body"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                </div>
                <div class="w-100 mb-3">
                  <h5>Attachment</h5>
                  <div @click="uploadFile()">
                    <material-input
                      style="display: none"
                      type="file"
                      id="uploadFile"
                      @change="handleFileUpload($event)"
                      :multiple="true"
                    />
                    <a class="text-decoration-underline tag-a-custom"
                      >Select Files For Upload</a
                    >
                  </div>
                  <div v-if="email.files.length" class="mt-3 mb-3">
                    <div class="d-flex justify-content-between mb-2" v-for="(file, index) in email.files" :key="index" >
                      <div class="text-decoration-underline tag-a-custom">
                        {{ file.name }}
                      </div>
                      <div class="ms-3 d-flex align-items-center" @click="removeFileUpload(index)">
                        <i
                          class="fas fa-trash-alt cursor-pointer ms-2"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div v-if="fileUploadError" class="is-invalid">
                    {{ fileUploadError }}
                  </div>
                  <div>
                    Maximum 5.00 MB per file
                  </div>
                  <div>
                    Total attachment size: 25.00 MB
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12 col-md-12 col-12 mx-auto">
              <div class="c-email-step">
                <h3 class="title-page mb-3">Step 3</h3>
                <div>
                  <MaterialRadio
                    v-model="email.type"
                    class="ps-0"
                    id="one_time_per_member"
                    value="1"
                  >
                    Send right now
                  </MaterialRadio>
                  <MaterialRadio
                    v-model="email.type"
                    class="ps-0"
                    id="mutiple_times_per_member"
                    value="2"
                  >
                    Schedule
                  </MaterialRadio>
                </div>
                <div class="d-flex align-items-center mt-3" v-if="email.type == 2">
                  <Multiselect
                    v-model="email.schedule_type"
                    :options="scheduleList"
                    class="mw-300 h-40px m-0 me-2"
                    placeholder="Select menu"
                    :canClear="false"
                  />
                  <Multiselect
                    v-if="email.schedule_type == 3"
                    v-model="email.day"
                    :options="days"
                    class="mw-300 h-40px m-0 me-2"
                    placeholder="Select day"
                    :canClear="false"
                  />
                  <Datepicker
                    v-if="[1, 4, 5].includes(email.schedule_type)"
                    v-model="email.send_date"
                    :enableTimePicker="false"
                    class="date-time h-40px me-2"
                    :format="format"
                  />
                  <Datepicker
                    v-model="email.send_time"
                    timePicker
                    class="date-time h-40px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-5">
          <material-button
            @click="saveEmail(2)"
            class="btn btn-sm btn-background"
            >Save as Draft</material-button
          >
          <material-button
            @click="saveEmail()"
            class="btn btn-sm btn-background ms-4"
            >{{ email.id ? 'Save' : (email.type == 1 ? 'Send' : 'Save') }}</material-button
          >
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
  <AddRecipientsModal
    :keyReload="keyReload"
    :membersIdSelected="membersIdSelected"
    :membersSelected="membersSelected"
    @select-recipients="selectRecipients"
    @reload-recipient-group="reloadRecipientGroup"
  ></AddRecipientsModal>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import CommonFunctionService from "../../../services/commonFunction.service";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import AddRecipientsModal from './AddRecipientsModal.vue';
import $ from "jquery";
import moment from "moment";
import EmailSetupService from "../../../services/emailSetup.service";
import MaterialTextarea from "@/components/MaterialTextarea.vue";

export default {
  name: "create-menu",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialInput,
    MaterialCheckbox,
    MaterialButton,
    MaterialRadio,
    Multiselect,
    Datepicker,
    AddRecipientsModal,
    MaterialTextarea
  },
  data() {
    return {
      snackbar: null,
      message: "",
      loaderActive: false,
      keyReload: 'key-reload',
      merchantName: localStorage.getItem("MERCHANT_NAME", ""),
      email: {
        id: this.$route.params.id ?? 0,
        subject: "",
        body: "",
        recipient_group_ids: [],
        members_id_select: [],
        files: [],
        type: 1,
        schedule_type: 1,
        status: 1,
        send_date: null,
        send_time: null,
        day: 1,
        month: 1,
      },
      membersIdSelected: [],
      membersSelected: [],
      recipientGroups: [],
      mailToMemberText: "",
      editor: ClassicEditor,
      editorConfig: {},
      scheduleList: [
        {
          label: 'Future date',
          value: 1,
        },
        {
          label: 'Daily',
          value: 2,
        },
        {
          label: 'Weekly on',
          value: 3,
        },
        {
          label: 'Monthly on',
          value: 4,
        },
        {
          label: 'Annually on',
          value: 5,
        }
      ],
      days: [
        {
          label: 'Sunday',
          value: 0,
        },
        {
          label: 'Monday',
          value: 1,
        },
        {
          label: 'TuesDay',
          value: 2,
        },
        {
          label: 'Wednesday',
          value: 3,
        },
        {
          label: 'Thursday',
          value: 4,
        },
        {
          label: 'Friday',
          value: 5,
        },
        {
          label: 'Saturday',
          value: 6,
        },
      ],
      filesDelete: [],
      fileUploadError: "",
      fieldRequired: [],
    };
  },
  async created() {
    await this.getRecipientGroups();

    if (this.email.id) {
      await this.getEmailDetail();
    }
  },
  async mounted() {
  },
  methods: {
    uploadFile() {
      $("#uploadFile").click();
    },

    handleFileUpload(event) {
      this.fileUploadError = "";
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      let maxFileSize = 5 * 1024 * 1024;
      let totalMaxFileSize = 5 * maxFileSize;
      let totalFileSize = 0;
      this.email.files.forEach((value) => {
        totalFileSize += value.size
      });

      for (let index = 0; index < files.length; index++) {
        let file = files[index];

        if (file.size > maxFileSize) {
          this.fileUploadError = 'File too big (> 5MB)';
          return;
        }

        totalFileSize += file.size;

        if (totalFileSize > totalMaxFileSize) {
          this.fileUploadError = 'Total file size exceeded (25MB)';
          return;
        }

        this.email.files.push(file);
      }
    },

    removeFileUpload(index) {
      if (this.email.files[index].id) {
        this.filesDelete.push(this.email.files[index].id);
      }

      this.email.files.splice(index, 1);
    },

    format(date) {
      return CommonFunctionService.formatDateShow(date)
    },

    openModalSelectRecipients() {
      this.keyReload = Math.random();
      $('#show-modal-add-recipients').click();
    },

    selectRecipients(membersId, members) {
      this.snackbar = "success";
      this.message = "Add Recipients Success";
      this.setTimeOutNotification();
      this.membersIdSelected = membersId;
      this.membersSelected = members;
      this.email.members_id_select = membersId;
      this.mailToMemberText = "To: ";
      this.membersSelected.forEach((member, index) => {
        this.mailToMemberText += (member.name ?? '') + '<' + (member.email ?? '') + '>'

        if (index < members.length - 1) {
          this.mailToMemberText += ', ';
        }
      });
    },

    reloadRecipientGroup(mess) {
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.getRecipientGroups();
    },

    checkrecipientGroups(group, index) {
      if ($('#recipients-group-' + index).is(':checked')) {
        this.email.recipient_group_ids.push(group.id);
      } else {
        this.email.recipient_group_ids.splice(this.email.recipient_group_ids.indexOf(group.id), 1)
      }
    },

    async getEmailDetail() {
      var emailId = this.$route.params.id;
      if (emailId) {
        this.showLoader();
        await EmailSetupService.emailDetail(emailId).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.email = response.data.data;
              this.membersIdSelected = this.email.members_id_select;
              this.membersSelected = this.email.members_select;
              this.mailToMemberText = "To: ";
              this.membersSelected.forEach((member, index) => {
                this.mailToMemberText += (member.name ?? '') + '<' + (member.email ?? '') + '>'

                if (index < this.membersSelected.length - 1) {
                  this.mailToMemberText += ', ';
                }
              });
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    async getRecipientGroups() {
      this.showLoader();
      await EmailSetupService.getRecipientGroups().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.recipientGroups = response.data.data;
          } else {
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },

    saveEmail(status = 1) {
      this.email.send_date = this.dateTimeFormat(
        this.email.send_date,
        this.email.send_time
      );
      this.email.status = status;
      
      if (this.processValidate()) {
        this.showLoader();
        var emailId = this.$route.params.id ?? 0;
        this.email.members_select = null;

        if (emailId) {
          //update email
          this.email.files_delete = this.filesDelete;
          EmailSetupService.updateEmail(this.email).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update Email Success";
                this.setTimeOutNotification();
                setTimeout(() => {
                  this.$router.push("/email-setup");
                }, 1000);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          //create email
          EmailSetupService.saveEmail(this.email).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.clearData();
                this.snackbar = "success";
                this.message = "Create Email Success";
                this.setTimeOutNotification();
                setTimeout(() => {
                  this.$router.push("/email-setup");
                }, 1000);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    resetRequired() {
      this.fieldRequired.isEmailSubjectRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.email.subject == "" || this.email.subject == null) {
        this.fieldRequired.isEmailSubjectRequire = true;
        isValid = false;
      }

      return isValid;
    },

    dateTimeFormat(startDate, startTime) {
      if (startDate == null) {
        return null;
      } else {
        if (startTime == null) {
          return moment(startDate).format("YYYY-MM-DD") + " " + "00:00:00";
        } else {
          return (
            moment(startDate).format("YYYY-MM-DD") +
            " " +
            moment(startTime).format("H:m")
          );
        }
      }
    },

    backToEmailSetup() {
      this.$router.push("/email-setup");
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    clearData() {
      this.email = {
        id: this.$route.params.id ?? 0,
        subject: "",
        body: "",
        recipient_group_ids: [],
        members_id_select: [],
        files: [],
        type: 1,
        schedule_type: 1,
        status: 1,
        send_date: null,
        send_time: null,
        day: 1,
        month: 1,
      };
      this.fileUploadError = "";
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.page-header .container {
  color: #222 !important;
}
.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 80%;
  height: 350px;
}
.add-category {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-35 {
  width: 35%;
}
.w-65 {
  width: 65%;
}
.css-icon-upload {
  font-size: 120px !important;
  margin-top: 4rem;
}

.css-icon {
  position: absolute;
  left: 17px !important;
  font-size: 18px !important;
  top: 7px !important;
}

.cate-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d5d6d8;
  background: #ebecee !important;
  padding: 15px 0;
  border-radius: 5px;
}

.category-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border: 1px solid #d5d6d8;
  color: #133067;
  border-radius: 5px;
}

.category-box:last-child {
  margin-bottom: 50px;
}

.cate-card {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
}

.card-item.add-items {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0 !important;
}
.card-item {
  border: 1px solid #d5d6d8;
  color: #133067;
  border-radius: 5px;
  padding: 7px;
  margin-top: 15px;
}

.card-item img {
  width: 175px !important;
  height: 175px !important;
}

.card-item .card-content {
  width: calc(100% - 200px) !important;
}

.material-icons-round {
  width: 30px;
}

.cl-22 {
  color: #222 !important;
}

.mw-180px {
  min-width: 180px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}

.schedule-list {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.schedule-list::-webkit-scrollbar {
  width: 7px;
}

.schedule-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.schedule-list::-webkit-scrollbar-thumb {
  background: #888;
}

.schedule-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-menu {
  border: 1px solid #d5d6d8;
  min-width: 9rem;
}
.cursor-pointer {
  cursor: pointer !important;
}

.unset-ov {
  overflow: unset !important;
}

.table-responsive thead tr th {
  padding: 0.5rem !important;
}

.activeLayout {
  background: white;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}

.inActiveLayout {
  /* background: gray; */
  align-items: center !important;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}

.btn-back h4 {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.c-email-step {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 24px;
  height: 100%;
}

.recipients-list {
  padding: 24px;
  margin-top: 25px;
  background: #ebecee;
  border-radius: 5px;
}

.tag-a-custom {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.mw-300 {
  max-width: 300px !important;
}

.h-40px {
  height: 40px !important;
}
</style>
