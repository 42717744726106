<template>
  <div>
    <div class="row my-2 d-flex">
      <div class="col-lg-6 col-md-6 col-12 text-title">{{ $t('LoyaltyMemberlist') }}</div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="notifications">
            <material-alert
              v-if="error"
              color="danger"
              icon="ni ni-like-2 ni-lg"
              dismissible
            >
              {{ error }}
            </material-alert>
          </div>
          <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
            <div class="row my-3 d-flex align-items-left">
              <div class="col-lg-5 col-md-5 col-12">
                <input
                  class="nosubmit"
                  type="search"
                  id="keyword_search"
                  v-model="searchKeyword"
                  extraClass
                  name="keyword_search"
                  placeholder="Search for member name, id, phone number"
                  @input="onChangesearchKeyword"
                />
              </div>
              <div class="col-lg-7 col-md-7 col-12">
                <div class="row">
                  <div class="dropdown filter-area col-lg-12 col-md-12 col-12">
                    <div class="filter-area1 col-lg-3 col-md-6 col-8 ps-2">
                      <Multiselect
                        v-model="tier_id"
                        :options="tiers"
                        @change="changeTierId"
                        :canClear="false"
                      />
                    </div>
                    <div class="filter-area2 col-lg-3 col-md-6 col-8 ps-2">
                      <Multiselect
                        v-model="gender"
                        :options="genders"
                        @change="chagnegGender"
                        :canClear="false"
                      />
                    </div>
                    <div class="dropdown filter-area3 col-lg-3 col-md-6 col-8 ps-2">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        aria-haspopup="true"
                        :disabled="disabled"
                      >
                        {{ $t('bulkAction') }}
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a class="dropdown-item" @click="selectVoucher()" href="#">Send Voucher</a>
                        </li>
                      </ul>
                    </div>
                    <div class="dropdown col-lg-3 col-md-6 col-8 ps-2 d-flex justify-content-end">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2"
                        data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                        {{ $t('download') }}
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        <li>
                          <a class="dropdown-item" @click="exportFile('csv')" href="#">Export CSV</a>
                        </li>
                        <li>
                          <a class="dropdown-item" @click="exportFile('xlsx')" href="#">Export Excel</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-4">
              <div class="col-12">
                <div class="card-custom">
                  <div class="table-responsive p-0 custom-table">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th
                            class="
                              w-2
                              text-center
                              font-weight-bolder
                              text-label
                            "
                          >
                            <material-checkbox
                              @click="checkAll"
                              :checked="isCheckedAll"
                              class="align-items-center checkbox-custom"
                            ></material-checkbox>
                          </th>
                          <th class="w-5 text-center font-weight-bolder">{{ $t('ID') }}</th>
                          <th class="w-5 text-center font-weight-bolder">{{ $t('Member_ID') }}</th>
                          <th class="text-center font-weight-bolder">
                            {{ $t('phoneNo') }}
                          </th>
                          <th class="text-center font-weight-bolder">{{ $t('First Name') }}</th>
                          <th class="text-center font-weight-bolder">{{ $t('Last Name') }}</th>
                          <th class="text-center font-weight-bolder">{{ $t('email') }}</th>
                          <th class="text-center font-weight-bolder">{{ $t('DOB') }}</th>
                          <th class="text-center font-weight-bolder">{{ $t('gender') }}</th>
                          <th class="text-center font-weight-bolder">
                            {{ $t('joinDate') }}
                          </th>
                          <th class="text-center font-weight-bolder">
                            {{ $t('lastPurchase') }}
                          </th>
                          <th class="text-center font-weight-bolder">{{ $t('status') }}</th>
                          <th class="text-center font-weight-bolder">{{ $t('action') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(value, key) in members" :key="key">
                          <td class="text-center text-xss">
                            <MaterialCheckbox
                              @click="checkItem(value.id)"
                              :checked="idChecked.includes(value?.id)"
                              :value="value.id"
                              class="align-items-center checkbox-custom"
                            />
                          </td>
                          <td class="text-center text-xss w-5 cursor-pointer" @click="editMember(value.id)">
                            {{ value.id_text }}
                          </td>
                          <td class="text-center text-xss w-5 cursor-pointer" @click="editMember(value.id)">
                            {{ value.member_id_text }}
                          </td>
                          <td class="text-center text-xss cursor-pointer" @click="editMember(value.id)">
                            {{ value.phone_number }}
                          </td>
                          <td class="text-left text-xss custom-title">
                            {{ value.name }}
                          </td>
                          <td class="text-left text-xss custom-title">
                            {{ value.last_name }}
                          </td>
                          <td class="text-left text-xss">
                            {{ value.email }}
                          </td>
                          <td class="text-center text-xss">
                            {{ value.date_of_birth }}
                          </td>
                          <td class="text-center text-xss">
                            {{ value.gender }}
                          </td>
                          <td class="text-center text-xss">
                            {{ value.join_date }}
                          </td>
                          <td class="text-center text-xss">
                            {{ value.last_purchase }}
                          </td>
                          <td class="text-center text-xss w-5">
                            <i
                              class="fa fa-lock"
                              v-if="value.block == 0"
                              style="color: #ff3300"
                            ></i>
                            <i
                              class="fa fa-check-square-o"
                              style="color: #33cc33"
                              v-else
                            ></i>
                          </td>
                          <td class="w-5 align-middle text-center text-sm">
                            <material-button
                              size="sm"
                              class="btn-background"
                              @click="editMember(value.id)"
                            >
                              View
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalSelectVoucher
        :confirmIssue="confirmIssue"
        :vouchers="vouchers"
        :changeSelectVoucher="changeSelectVoucher"
        :isVoucherRequire="isVoucherRequire"
        :voucherDefault="voucherId"
      />
      <ModalConfirmSelectVoucher
        :message="`Are you sure to issue selected to ${idChecked.length} members ?`"
        btnYes="OK"
        btnNo="Cancel"
        :okIssue="okIssue"
        :cancelIssue="cancelIssue"
      />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
    <form :action="`${actionDownload}/merchant/download-members`" method="POST">
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="search_keyword" :value="searchKeyword" />
      <input type="hidden" name="gender" :value="gender" />
      <input type="hidden" name="tier_id" :value="tier_id" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="page" :value="page" />
      <input type="hidden" name="action" value="export" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import MemberService from "../../services/member.service";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import ModalSelectVoucher from "@/components/ModalSelectVoucher.vue";
import ModalConfirmSelectVoucher from "@/components/ModalConfirmSelectVoucher.vue";
import TokenService from "../../services/token.service";
import $ from "jquery";
export default {
  name: "find-store",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Pagination,
    MaterialCheckbox,
    Multiselect,
    ModalSelectVoucher,
    ModalConfirmSelectVoucher
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      members: [],
      searchKeyword: "",
      searchTimerId: null,
      pagination: {},
      page: 1,
      memberId: null,
      isLoadData: false,
      merchantCode: "",
      total: 0,
      idChecked: [],
      memberIds: "",
      isCheckedAll: false,
      filter_tier: 0,
      filter_gender: 0,
      tiers: [],
      tier_id: 0,
      genders: [],
      gender: 0,
      vouchers: [],
      voucherId: 0,
      isVoucherRequire: false,
      disabled: false,
      action_export: 'csv',
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getList(this.page);
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    exportFile(type = 'csv') {
      this.action_export = type;
      setTimeout(() => {
        $('#downloadForm').click();
      }, 150);
    },

    changeTierId(tierId) {
      this.tier_id = tierId
      this.getList(this.page);
    },

    chagnegGender(gender) {
      this.gender = gender
      this.getList(this.page);
    },

    selectVoucher() {
      if(this.idChecked.length > 0) {
        $('.btn-select-voucher-modal').click()
        this.vouchers = [];
        this.showLoader();
        MemberService.getVoucherAvailabel().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.vouchers = response.data.data
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        this.snackbar = "danger"
        this.message = "Please select members!"
        this.setTimeOutNotification()
      }
    },

    changeSelectVoucher(voucherId) {
      this.voucherId = voucherId
    },

    confirmIssue() {
      this.isVoucherRequire = false
      if(this.voucherId) {
        $('.btn-close-select-voucher-modal').click()
        $('.btn-confirm-select-voucher-modal').click()
      } else {
        this.isVoucherRequire = true
      }
    },

    okIssue() {
      $('.btn-close-confirm-select-voucher-modal').click()
      this.disabled = true
      this.showLoader();
      var dataForm = {
        voucher_id: this.voucherId,
        member_ids: this.idChecked
      };
      MemberService.bulkVoucher(1, dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.voucherId = 0
            this.snackbar = "success"
            this.message = "Sent voucher successful"
            this.setTimeOutNotification()
          } else {
            this.snackbar = "danger"
            this.message = response.data.result.message
            this.setTimeOutNotification()
          }
          this.hideLoader()
          this.disabled = false
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    cancelIssue() {
      this.voucherId = 0
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList(this.page);
      }, 1000);
    },

    getList(page) {
      this.isLoadData = false;
      this.members = [];
      this.showLoader();
      var dataForm = {
        search_keyword: this.searchKeyword,
        page: page,
        gender: this.gender,
        tier_id: this.tier_id
      };
      MemberService.getDataMembers(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.members = response.data.data.members.items;
            this.tiers = response.data.data.tiers
            this.genders = response.data.data.genders
            this.pagination = response.data.data.members;
            this.page = this.pagination.current_page;
            this.total = this.pagination.total;
            this.$router.push({
              query: Object.assign({}, this.$route.query, { page: this.page }),
            });
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.checkAllStatus();
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    createMember() {
      this.$router.push("/member");
    },

    editMember(id) {
      this.$router.push(
        "/member/" + id + "?page=" + this.page
      );
    },

    processYes() {
      $(".btn-close-modal").click();
      this.showLoader();
      MemberService.deleteMember(this.memberId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            if (this.members.length == 1) {
              this.page = this.page - 1;
            }
            this.getList(this.page);
            this.snackbar = "success";
            this.message = "Delete Member On Merchant Success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    async checkAll() {
      await this.members.forEach((value) => {
        this.deleteItem(value.id);
      });
      if (!this.isCheckedAll) {
        await this.members.forEach((value) => {
          this.idChecked.push(value.id);
        });
      }
      this.checkAllStatus();
      this.memberIds = this.idChecked.join(",");
    },
    checkItem(memberId) {
      if (this.idChecked.includes(memberId)) {
        this.deleteItem(memberId);
      } else {
        this.idChecked.push(memberId);
      }
      this.checkAllStatus();
      this.memberIds = this.idChecked.join(",");
    },
    deleteItem(memberId) {
      this.idChecked.forEach((value, index) => {
        if (value == memberId) {
          this.idChecked.splice(index, 1);
        }
      });
    },
    async checkAllStatus() {
      this.isCheckedAll = true;
      await this.members.forEach((value) => {
        if (!this.idChecked.includes(value.id)) {
          this.isCheckedAll = false;
          return;
        }
      });
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.text-title {
  color: #133067  !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.text-label {
  color: #5b848d !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.checkbox-auto-save label {
  padding-top: 11px !important;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 1px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.custom-title {
  word-break: break-all;
  max-width: 200px;
  white-space: normal;
}

@media only screen and (min-width: 1300px) {
  .filter-area {
    display: flex;
  }
}

@media only screen and (max-width: 1300px) {
  .filter-area1, .filter-area2, .filter-area3 {
    margin-top: 10px;
  }
}
</style>