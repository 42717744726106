import api from './api';

class AnalyticsService {
    dailyCollectionsReport(dataForm) {
        return api.post('/merchant/analytics/daily-collections',  dataForm);
    }

    collectionByBranchReport(dataForm) {
        return api.post('/merchant/analytics/collection-by-branch',  dataForm);
    }

    itemSalesByBranchReport(dataForm) {
        return api.post('/merchant/analytics/item-sales-by-branch',  dataForm);
    }
}

export default new AnalyticsService();